<template>
  <div class="mb-5 main-admissao fade-in-up">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">Matrícula</h1>
    </b-jumbotron>
    <bread-cpv></bread-cpv>

    <b-container>
      <b-jumbotron class="bg-white p-0" style="text-align: center">
        <template #header>
          <span style="color: #333; font-size: 25.6px; font-weight: 600">
            Será um prazer receber a sua família no Colégio CPV!
          </span>
        </template>
        <template #lead>
          <span style="color: #333; font-size: 19.2px !important">
            Após o preenchimento do formulário abaixo, entraremos em contato para confirmar os dados da matrícula.
          </span>
        </template>
      </b-jumbotron>

      <hr />

      <b-form @submit.prevent="handleSubmit">
        <b-card
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          header-class="d-flex justify-content-between align-items-center"
          class="callout-top-primary mt-3"
        >
          <template v-slot:header>
            <h5 class="mb-0">Dados do Curso</h5>
          </template>

          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group label="Unidade" label-for="unidade_id" label-class="font-weight-bold required-field">
                <b-form-select
                  id="unidade_id"
                  name="unidade_id"
                  v-model.trim="$v.matricula.gerais.unidade_id.$model"
                  disabled
                >
                  <option v-for="unit in unidades" v-bind:key="unit.id" :value="unit.id" selected>
                    {{ unit.nome }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.unidade_id.$invalid"
                >
                  <p>Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4">
              <b-form-group
                label="Série de interesse"
                label-for="serie_interesse"
                label-class="font-weight-bold required-field"
              >
                <b-form-select
                  v-model.trim="$v.matricula.gerais.grade_id.$model"
                  id="serie_interesse"
                  name="serie_interesse"
                >
                  <option value="" disabled>Selecione a série</option>
                  <option v-for="grade in grades" :key="grade.id" :value="grade.id">
                    {{ grade.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.grade_id.$invalid">
                  <p>Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4">
              <b-form-group label="Ano Letivo" label-for="ano_letivo" label-class="font-weight-bold required-field">
                <b-form-select id="ano_letivo" name="ano_letivo" v-model.trim="$v.matricula.gerais.ano_letivo.$model">
                  <option value="" disabled>Selecione o ano letivo</option>
                  <option v-for="ano in anosFuturos" :key="ano" :value="ano">{{ ano }}</option>
                </b-form-select>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.ano_letivo.$invalid"
                >
                  <p>Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="callout-top-primary mt-3"
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          header-class="d-flex justify-content-between align-items-center"
        >
          <template v-slot:header>
            <h5 class="mb-0">Dados do Aluno</h5>
          </template>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Nome Completo" label-for="aluno_nome" label-class="font-weight-bold required-field">
                <b-form-input
                  type="text"
                  v-model.trim="$v.matricula.aluno.nome.$model"
                  id="aluno_nome"
                  name="aluno_nome"
                  debounce="500"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.nome.$invalid">
                  <p v-if="$v.matricula.aluno.nome.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.nome.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group label="E-mail" label-for="aluno_email" label-class="font-weight-bold required-field">
                <b-form-input
                  type="text"
                  v-model.trim="$v.matricula.aluno.email.$model"
                  id="aluno_email"
                  name="aluno_email"
                  debounce="500"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.email.$invalid">
                  <p v-if="$v.matricula.aluno.email.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.email.email == false">E-mail inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group label="CPF" label-for="aluno_cpf" label-class="font-weight-bold required-field">
                <the-mask
                  id="aluno_cpf"
                  v-model="$v.matricula.aluno.cpf.$model"
                  :mask="'###.###.###-##'"
                  type="text"
                  debounce="1000"
                  placeholder="###.###.###-##"
                  class="form-control"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cpf.$invalid">
                  <p v-if="$v.matricula.aluno.cpf.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.cpf.cpfSize == false">O cpf deve conter 11 números</p>
                  <p v-if="$v.matricula.aluno.cpf.cpfValid == false">CPF Inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="RG" label-for="aluno_rg" label-class="font-weight-bold required-field">
                <b-form-input type="text" v-model.trim="$v.matricula.aluno.rg.$model" name="aluno_rg" id="aluno_rg" />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.rg.$invalid">
                  <p v-if="$v.matricula.aluno.rg.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.rg.rgValid == false">RG inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Celular" label-for="aluno_celular" label-class="font-weight-bold required-field">
                <the-mask
                  id="aluno_celular"
                  name="aluno_celular"
                  v-model.trim="$v.matricula.aluno.celular.$model"
                  :mask="['(##) #####-####']"
                  type="text"
                  placeholder="(##) #####-####"
                  class="form-control"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.celular.$invalid">
                  <p v-if="$v.matricula.aluno.celular.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.celular.celularSize == false">Celular inválido</p>
                  <p v-if="$v.matricula.aluno.celular.numeric == false">Campo deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Data de Nascimento"
                label-for="aluno_nascimento"
                label-class="font-weight-bold required-field"
              >
                <date-picker
                  id="aluno_nascimento"
                  name="aluno_nascimento"
                  v-model.trim="$v.matricula.aluno.nascimento.$model"
                  lang="pt-br"
                  type="date"
                  value-type="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                  input-class="cls-datepicker"
                  :editable="true"
                  :open.sync="openDatePickerAluno"
                >
                  <template v-slot:input>
                    <masked-input
                      type="text"
                      name="aluno_nascimento_input"
                      class="mx-input"
                      :value="$v.matricula.aluno.nascimento.$model"
                      :mask="dateMask"
                      :guide="false"
                      @change="handleChangeAluno"
                    >
                    </masked-input>
                  </template>
                  <template #footer="{ emit }">
                    <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                  </template>
                </date-picker>

                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.nascimento.$invalid"
                >
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Gênero" label-for="aluno_genero" label-class="font-weight-bold required-field">
                <b-form-select id="aluno_genero" name="aluno_genero" v-model.trim="$v.matricula.aluno.genero.$model">
                  <option value="" disabled>Selecione o gênero</option>
                  <option v-for="gen in opt_genero" v-bind:key="gen.id" :value="gen.id">{{ gen.name }}</option>
                </b-form-select>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.genero.$invalid">
                  <p v-if="$v.matricula.aluno.genero.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Outro Gênero"
                label-for="aluno_outro_genero"
                label-class="font-weight-bold required-field"
                v-show="$v.matricula.aluno.genero.$model === otherGenreId"
              >
                <b-form-input
                  type="text"
                  v-model.trim="$v.matricula.aluno.outro_genero.$model"
                  id="aluno_outro_genero"
                  name="aluno_outro_genero"
                  debounce="500"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.outro_genero.$invalid"
                >
                  <p v-if="$v.matricula.aluno.outro_genero.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.outro_genero.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group
                label="Escola de Origem"
                label-for="aluno_escola"
                label-class="font-weight-bold required-field"
              >
                <b-form-input
                  type="text"
                  v-model.trim="$v.matricula.aluno.escola.$model"
                  id="aluno_escola"
                  name="aluno_escola"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.escola.$invalid">
                  <p v-if="$v.matricula.aluno.escola.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.escola.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group
                label="Como conheceu?"
                label-for="comoconheceu"
                label-class="font-weight-bold required-field"
              >
                <b-form-select
                  v-model.trim="$v.matricula.aluno.comoconheceu.$model"
                  name="comoconheceu"
                  id="comoconheceu"
                >
                  <b-form-select-option value="">Selecione</b-form-select-option>
                  <b-form-select-option value="Ex-alunos">Ex-alunos</b-form-select-option>
                  <b-form-select-option value="Google / Internet">Google / Internet</b-form-select-option>
                  <b-form-select-option value="Moro perto da escola">Moro perto da escola</b-form-select-option>
                  <b-form-select-option value="Clubes e academias">Clubes e academias</b-form-select-option>
                  <b-form-select-option value="Blog dos Colégios / Estadão"
                    >Blog dos Colégios / Estadão</b-form-select-option
                  >
                  <b-form-select-option value="Panfletagem">Panfletagem</b-form-select-option>
                  <b-form-select-option value="Mídias Sociais">Mídias Sociais</b-form-select-option>
                  <b-form-select-option value="Jornais / Revistas">Jornais / Revistas</b-form-select-option>
                  <b-form-select-option value="Indicação">Indicação</b-form-select-option>
                  <b-form-select-option value="Mala direta">Mala direta</b-form-select-option>
                  <b-form-select-option value="Irmão de Aluno">Irmão de Aluno</b-form-select-option>
                  <b-form-select-option value="Funcionário">Funcionário</b-form-select-option>
                  <b-form-select-option value="Outros">Outros</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.comoconheceu.$invalid"
                >
                  <p v-if="$v.matricula.aluno.comoconheceu.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider">Endereço</div>
          <b-row>
            <b-col cols="12" sm="2">
              <b-form-group label="CEP" label-for="aluno_cep" label-class="font-weight-bold required-field">
                <b-input-group>
                  <the-mask
                    id="aluno_cep"
                    name="aluno_cep"
                    v-model.trim="$v.matricula.aluno.cep.$model"
                    :mask="'#####-###'"
                    type="text"
                    debounce="1000"
                    placeholder="#####-##"
                    class="form-control"
                  />

                  <template #append>
                    <b-button variant="outline-secondary" @click="buscaCepAluno()" :disabled="isLoadingCepAluno">
                      <i class="fa-solid fa-magnifying-glass"></i
                    ></b-button>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cep.$invalid">
                  <p v-if="$v.matricula.aluno.cep.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.cep.numeric == false">Campo deve ser numérico</p>
                  <p v-if="$v.matricula.aluno.cep.cepSize == false">Cep inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="3">
              <b-form-group label="Bairro" label-for="aluno_bairro" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.aluno.bairro.$model"
                    type="text"
                    id="aluno_bairro"
                    name="aluno_bairro"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepAluno">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>

                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.bairro.$invalid">
                  <p v-if="$v.matricula.aluno.bairro.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.bairro.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="5">
              <b-form-group label="Cidade" label-for="aluno_cidade" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.aluno.cidade.$model"
                    type="text"
                    id="aluno_cidade"
                    name="aluno_cidade"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepAluno">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cidade.$invalid">
                  <p v-if="$v.matricula.aluno.cidade.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.cidade.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-form-group label="UF" label-for="aluno_estado" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.aluno.estado.$model"
                    type="text"
                    id="aluno_estado"
                    name="aluno_estado"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepAluno">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.estado.$invalid">
                  <p v-if="$v.matricula.aluno.estado.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.estado.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Endereço" label-for="aluno_endereco" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.aluno.endereco.$model"
                    type="text"
                    id="aluno_endereco"
                    name="aluno_endereco"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepAluno">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.endereco.$invalid">
                  <p v-if="$v.matricula.aluno.endereco.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.endereco.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-form-group label="Número" label-for="aluno_numero" label-class="font-weight-bold required-field">
                <b-form-input
                  v-model.trim="$v.matricula.aluno.numero.$model"
                  type="text"
                  id="aluno_numero"
                  name="aluno_numero"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.numero.$invalid">
                  <p v-if="$v.matricula.aluno.numero.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.aluno.numero.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4">
              <b-form-group label="Complemento" label-for="aluno_complemento" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.aluno.complemento.$model"
                  type="text"
                  id="aluno_complemento"
                  name="aluno_complemento"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.complemento.$invalid"
                >
                  <p v-if="$v.matricula.aluno.complemento.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="callout-top-primary mt-3"
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          header-class="d-flex justify-content-between align-items-center"
        >
          <template v-slot:header>
            <h5 class="mb-0">Dados do Pai</h5>
            <b-link @click="copiaAlunoPai()">Copiar endereço do aluno</b-link>
          </template>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Nome Completo" label-for="pai_nome" label-class="font-weight-bold required-field">
                <b-form-input v-model.trim="$v.matricula.pai.nome.$model" type="text" id="pai_nome" name="pai_nome" />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.nome.$invalid">
                  <p v-if="$v.matricula.pai.nome.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.nome.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group label="E-mail" label-for="pai_email" label-class="font-weight-bold required-field">
                <b-form-input
                  v-model.trim="$v.matricula.pai.email.$model"
                  type="text"
                  id="pai_email"
                  name="pai_email"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.email.$invalid">
                  <p v-if="$v.matricula.pai.email.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.email.email == false">E-mail inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group label="CPF" label-for="pai_cpf" label-class="font-weight-bold required-field">
                <the-mask
                  id="pai_cpf"
                  v-model.trim="$v.matricula.pai.cpf.$model"
                  :mask="'###.###.###-##'"
                  type="text"
                  debounce="1000"
                  placeholder="###.###.###-##"
                  class="form-control"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cpf.$invalid">
                  <p v-if="$v.matricula.pai.cpf.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.cpf.cpfSize == false">O cpf deve conter 11 números</p>
                  <p v-if="$v.matricula.pai.cpf.cpfValid == false">CPF Inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="RG" label-for="rg_pai" label-class="font-weight-bold required-field">
                <b-form-input v-model.trim="$v.matricula.pai.rg.$model" type="text" name="rg_pai" id="rg_pai" />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.rg.$invalid">
                  <p v-if="$v.matricula.pai.rg.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.rg.rgValid == false">RG inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Celular" label-for="pai_celular" label-class="font-weight-bold required-field">
                <the-mask
                  id="pai_celular"
                  name="pai_celular"
                  v-model.trim="$v.matricula.pai.celular.$model"
                  :mask="['(##) #####-####']"
                  type="text"
                  placeholder="(##) #####-####"
                  class="form-control"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.celular.$invalid">
                  <p v-if="$v.matricula.pai.celular.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.celular.celularSize == false">Celular inválido</p>
                  <p v-if="$v.matricula.pai.celular.numeric == false">Campo deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Data de Nascimento"
                label-for="pai_nascimento"
                label-class="font-weight-bold required-field"
              >
                <date-picker
                  id="pai_nascimento"
                  name="pai_nascimento"
                  v-model.trim="$v.matricula.pai.nascimento.$model"
                  lang="pt-br"
                  type="date"
                  value-type="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                  input-class="cls-datepicker"
                  :editable="true"
                  :open.sync="openDatePickerPai"
                >
                  <template v-slot:input>
                    <masked-input
                      type="text"
                      name="pai_nascimento_input"
                      class="mx-input"
                      :value="$v.matricula.pai.nascimento.$model"
                      :mask="dateMask"
                      :guide="false"
                      @change="handleChangePai"
                    >
                    </masked-input>
                  </template>
                  <template #footer="{ emit }">
                    <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                  </template>
                </date-picker>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.nascimento.$invalid">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Gênero" label-for="pai_genero" label-class="font-weight-bold">
                <b-form-select id="pai_genero" name="pai_genero" v-model.trim="$v.matricula.pai.genero.$model">
                  <option value="" disabled>Selecione o gênero</option>
                  <option v-for="gen in opt_genero" v-bind:key="gen.id" :value="gen.id">{{ gen.name }}</option>
                </b-form-select>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.genero.$invalid">
                  <p v-if="$v.matricula.pai.genero.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Outro Gênero"
                label-for="pai_outro_genero"
                label-class="font-weight-bold required-field"
                v-show="$v.matricula.pai.genero.$model === otherGenreId"
              >
                <b-form-input
                  type="text"
                  v-model.trim="$v.matricula.pai.outro_genero.$model"
                  id="pai_outro_genero"
                  name="pai_outro_genero"
                  debounce="500"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.pai.outro_genero.$invalid"
                >
                  <p v-if="$v.matricula.pai.outro_genero.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.outro_genero.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Profissão" label-for="pai_profissao" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.pai.profissao.$model"
                  type="text"
                  id="pai_profissao"
                  name="pai_profissao"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.profissao.$invalid">
                  <p v-if="$v.matricula.pai.profissao.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6">
              <b-form-group label="Empresa em que trabalha" label-for="pai_empresa" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.pai.empresa.$model"
                  type="text"
                  id="pai_empresa"
                  name="pai_empresa"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.empresa.$invalid">
                  <p v-if="$v.matricula.pai.empresa.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider">Endereço</div>
          <b-row>
            <b-col cols="12" sm="2">
              <b-form-group label="CEP" label-for="pai_cep" label-class="font-weight-bold required-field">
                <b-input-group>
                  <the-mask
                    id="pai_cep"
                    name="pai_cep"
                    v-model.trim="$v.matricula.pai.cep.$model"
                    :mask="'#####-###'"
                    type="text"
                    debounce="1000"
                    placeholder="#####-##"
                    class="form-control"
                  />
                  <template #append>
                    <b-button variant="outline-secondary" @click="buscaCepPai()" :disabled="isLoadingCepPai">
                      <i class="fa-solid fa-magnifying-glass"></i
                    ></b-button>
                  </template>
                </b-input-group>

                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cep.$invalid">
                  <p v-if="$v.matricula.pai.cep.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.cep.numeric == false">Campo deve ser numérico</p>
                  <p v-if="$v.matricula.pai.cep.cepSize == false">Cep inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Bairro" label-for="pai_bairro" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.pai.bairro.$model"
                    type="text"
                    id="pai_bairro"
                    name="pai_bairro"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepPai">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.bairro.$invalid">
                  <p v-if="$v.matricula.pai.bairro.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.bairro.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Cidade" label-for="pai_cidade" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.pai.cidade.$model"
                    type="text"
                    id="pai_cidade"
                    name="pai_cidade"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepPai">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cidade.$invalid">
                  <p v-if="$v.matricula.pai.cidade.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.cidade.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-form-group label="UF" label-for="pai_estado" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.pai.estado.$model"
                    type="text"
                    id="pai_estado"
                    name="pai_estado"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepPai">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.estado.$invalid">
                  <p v-if="$v.matricula.pai.estado.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.estado.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Endereço" label-for="pai_endereco" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.pai.endereco.$model"
                    type="text"
                    id="pai_endereco"
                    name="pai_endereco"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepPai">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.endereco.$invalid">
                  <p v-if="$v.matricula.pai.endereco.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.endereco.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-form-group label="Número" label-for="pai_numero" label-class="font-weight-bold required-field">
                <b-form-input
                  v-model.trim="$v.matricula.pai.numero.$model"
                  type="text"
                  id="pai_numero"
                  name="pai_numero"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.numero.$invalid">
                  <p v-if="$v.matricula.pai.numero.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.pai.numero.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4">
              <b-form-group label="Complemento" label-for="pai_complemento" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.pai.complemento.$model"
                  type="text"
                  id="pai_complemento"
                  name="pai_complemento"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.complemento.$invalid">
                  <p v-if="$v.matricula.pai.complemento.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="callout-top-primary mt-3"
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          header-class="d-flex justify-content-between align-items-center"
        >
          <template v-slot:header>
            <h5 class="mb-0">Dados da Mãe</h5>
            <b-link @click="copiaAlunoMae()">Copiar endereço do aluno</b-link>
          </template>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Nome Completo" label-for="mae_nome" label-class="font-weight-bold required-field">
                <b-form-input v-model.trim="$v.matricula.mae.nome.$model" type="text" id="mae_nome" name="mae_nome" />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.nome.$invalid">
                  <p v-if="$v.matricula.mae.nome.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.nome.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group label="E-mail" label-for="mae_email" label-class="font-weight-bold required-field">
                <b-form-input
                  v-model.trim="$v.matricula.mae.email.$model"
                  type="text"
                  id="mae_email"
                  name="mae_email"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.email.$invalid">
                  <p v-if="$v.matricula.mae.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.email == false">E-mail inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group label="CPF" label-for="mae_cpf" label-class="font-weight-bold required-field">
                <the-mask
                  id="mae_cpf"
                  v-model.trim="$v.matricula.mae.cpf.$model"
                  :mask="'###.###.###-##'"
                  type="text"
                  debounce="1000"
                  placeholder="###.###.###-##"
                  class="form-control"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cpf.$invalid">
                  <p v-if="$v.matricula.mae.cpf.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.cpf.cpfSize == false">O cpf deve conter 11 números</p>
                  <p v-if="$v.matricula.mae.cpf.cpfValid == false">CPF Inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="RG" label-for="mae_rg" label-class="font-weight-bold required-field">
                <b-form-input v-model.trim="$v.matricula.mae.rg.$model" type="text" name="mae_rg" id="mae_rg" />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.rg.$invalid">
                  <p v-if="$v.matricula.mae.rg.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.rg.rgValid == false">RG inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Celular" label-for="mae_celular" label-class="font-weight-bold required-field">
                <the-mask
                  id="mae_celular"
                  name="mae_celular"
                  v-model.trim="$v.matricula.mae.celular.$model"
                  :mask="['(##) #####-####']"
                  type="text"
                  placeholder="(##) #####-####"
                  class="form-control"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.celular.$invalid">
                  <p v-if="$v.matricula.mae.celular.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.celular.celularSize == false">Celular inválido</p>
                  <p v-if="$v.matricula.mae.celular.numeric == false">Campo deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Data de Nascimento"
                label-for="mae_nascimento"
                label-class="font-weight-bold required-field"
              >
                <date-picker
                  id="mae_nascimento"
                  name="mae_nascimento"
                  v-model.trim="$v.matricula.mae.nascimento.$model"
                  lang="pt-br"
                  type="date"
                  value-type="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                  input-class="cls-datepicker"
                  :editable="true"
                  :open.sync="openDatePickerMae"
                >
                  <template v-slot:input>
                    <masked-input
                      type="text"
                      name="mae_nascimento_input"
                      class="mx-input"
                      :value="$v.matricula.mae.nascimento.$model"
                      :mask="dateMask"
                      :guide="false"
                      @change="handleChangeMae"
                    >
                    </masked-input>
                  </template>
                  <template #footer="{ emit }">
                    <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                  </template>
                </date-picker>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.nascimento.$invalid">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Gênero" label-for="mae_genero" label-class="font-weight-bold">
                <b-form-select id="mae_genero" name="mae_genero" v-model.trim="$v.matricula.mae.genero.$model">
                  <option value="" disabled>Selecione o gênero</option>
                  <option v-for="gen in opt_genero" v-bind:key="gen.id" :value="gen.id">{{ gen.name }}</option>
                </b-form-select>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.genero.$invalid">
                  <p v-if="$v.matricula.mae.genero.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Outro Gênero"
                label-for="mae_outro_genero"
                label-class="font-weight-bold required-field"
                v-show="$v.matricula.mae.genero.$model === otherGenreId"
              >
                <b-form-input
                  type="text"
                  v-model.trim="$v.matricula.mae.outro_genero.$model"
                  id="mae_outro_genero"
                  name="mae_outro_genero"
                  debounce="500"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.mae.outro_genero.$invalid"
                >
                  <p v-if="$v.matricula.mae.outro_genero.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.outro_genero.alpha == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Profissão" label-for="mae_profissao" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.mae.profissao.$model"
                  type="text"
                  id="mae_profissao"
                  name="mae_profissao"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.profissao.$invalid">
                  <p v-if="$v.matricula.mae.profissao.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6">
              <b-form-group label="Empresa em que trabalha" label-for="mae_empresa" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.mae.empresa.$model"
                  type="text"
                  id="mae_empresa"
                  name="mae_empresa"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.empresa.$invalid">
                  <p v-if="$v.matricula.mae.empresa.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider">Endereço</div>
          <b-row>
            <b-col cols="12" sm="2">
              <b-form-group label="CEP" label-for="mae_cep" label-class="font-weight-bold required-field">
                <b-input-group>
                  <the-mask
                    id="mae_cep"
                    name="mae_cep"
                    v-model.trim="$v.matricula.mae.cep.$model"
                    :mask="'#####-###'"
                    type="text"
                    debounce="1000"
                    placeholder="#####-##"
                    class="form-control"
                    @blur.native="buscaCepMae()"
                  />
                  <template #append>
                    <b-button variant="outline-secondary" @click="buscaCepMae()" :disabled="isLoadingCepMae">
                      <i class="fa-solid fa-magnifying-glass"></i
                    ></b-button>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cep.$invalid">
                  <p v-if="$v.matricula.mae.cep.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.cep.numeric == false">Campo deve ser numérico</p>
                  <p v-if="$v.matricula.mae.cep.cepSize == false">Cep inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Bairro" label-for="mae_bairro" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.mae.bairro.$model"
                    type="text"
                    id="mae_bairro"
                    name="mae_bairro"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepMae">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.bairro.$invalid">
                  <p v-if="$v.matricula.mae.bairro.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.bairro.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Cidade" label-for="mae_cidade" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.mae.cidade.$model"
                    type="text"
                    id="mae_cidade"
                    name="mae_cidade"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepMae">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cidade.$invalid">
                  <p v-if="$v.matricula.mae.cidade.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.cidade.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-form-group label="UF" label-for="mae_estado" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.mae.estado.$model"
                    type="text"
                    id="mae_estado"
                    name="mae_estado"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepMae">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.estado.$invalid">
                  <p v-if="$v.matricula.mae.estado.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.estado.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Endereço" label-for="mae_endereco" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.mae.endereco.$model"
                    type="text"
                    id="mae_endereco"
                    name="mae_endereco"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepMae">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.endereco.$invalid">
                  <p v-if="$v.matricula.mae.endereco.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.endereco.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-form-group label="Número" label-for="mae_numero" label-class="font-weight-bold required-field">
                <b-form-input
                  v-model.trim="$v.matricula.mae.numero.$model"
                  type="text"
                  id="mae_numero"
                  name="mae_numero"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.numero.$invalid">
                  <p v-if="$v.matricula.mae.numero.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.mae.numero.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4">
              <b-form-group label="Complemento" label-for="mae_complemento" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.mae.complemento.$model"
                  type="text"
                  id="mae_complemento"
                  name="mae_complemento"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.complemento.$invalid">
                  <p v-if="$v.matricula.mae.complemento.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="mt-3"
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          header-class="d-flex justify-content-between align-items-center"
        >
          <template v-slot:header>
            <h5 class="mb-0">Responsável Financeiro</h5>
            <b-link v-if="$v.matricula.responsavel.responsavel.$model === 'outro'" @click="copiaAlunoResponsavel()"
              >Copiar endereço do aluno</b-link
            >
          </template>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col>
              <b-form-group
                label="Responsável Financeiro"
                label-for="responsavel"
                label-class="font-weight-bold required-field"
              >
                <b-form-radio-group
                  v-model="$v.matricula.responsavel.responsavel.$model"
                  :options="resp_options"
                  name="radio-inline"
                ></b-form-radio-group>

                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.responsavel.$invalid"
                >
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col v-if="$v.matricula.responsavel.responsavel.$model === 'outro'">
              <b-form-group
                label="Outro Responsável"
                label-for="outro_responsavel"
                label-class="font-weight-bold required-field"
              >
                <b-form-input
                  type="text"
                  v-model.trim="$v.matricula.responsavel.outro_responsavel.$model"
                  id="outro_responsavel"
                  name="outro_responsavel"
                  placeholder="Ex. Avós, Tios, Irmãos.. etc..."
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.outro_responsavel.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.outro_responsavel.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.outro_responsavel.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="callout-top-primary mt-3"
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          header-class="d-flex justify-content-between align-items-center"
          v-show="$v.matricula.responsavel.responsavel.$model === 'outro'"
        >
          <template v-slot:header>
            <h5 class="mb-0">Dados do Responsável Financeiro</h5>
          </template>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group
                label="Nome Completo"
                label-for="responsavel_nome"
                label-class="font-weight-bold required-field"
              >
                <b-form-input
                  v-model.trim="$v.matricula.responsavel.nome.$model"
                  type="text"
                  id="responsavel_nome"
                  name="responsavel_nome"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.nome.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.nome.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.nome.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group label="E-mail" label-for="responsavel_email" label-class="font-weight-bold required-field">
                <b-form-input
                  v-model.trim="$v.matricula.responsavel.email.$model"
                  type="text"
                  id="responsavel_email"
                  name="responsavel_email"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.email.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.email == false">E-mail inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group label="CPF" label-for="responsavel_cpf" label-class="font-weight-bold required-field">
                <the-mask
                  id="responsavel_cpf"
                  v-model.trim="$v.matricula.responsavel.cpf.$model"
                  :mask="'###.###.###-##'"
                  type="text"
                  debounce="1000"
                  placeholder="###.###.###-##"
                  class="form-control"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cpf.$invalid">
                  <p v-if="$v.matricula.responsavel.cpf.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.cpf.cpfSize == false">O cpf deve conter 11 números</p>
                  <p v-if="$v.matricula.responsavel.cpf.cpfValid == false">CPF Inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="RG" label-for="responsavel_rg" label-class="font-weight-bold required-field">
                <b-form-input
                  v-model.trim="$v.matricula.responsavel.rg.$model"
                  type="text"
                  name="responsavel_rg"
                  id="responsavel_rg"
                />
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.rg.$invalid">
                  <p v-if="$v.matricula.responsavel.rg.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.rg.rgValid == false">RG inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Celular"
                label-for="responsavel_celular"
                label-class="font-weight-bold required-field"
              >
                <the-mask
                  id="responsavel_celular"
                  name="responsavel_celular"
                  v-model.trim="$v.matricula.responsavel.celular.$model"
                  :mask="['(##) #####-####']"
                  type="text"
                  placeholder="(##) #####-####"
                  class="form-control"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.celular.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.celular.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.celular.celularSize == false">Celular inválido</p>
                  <p v-if="$v.matricula.responsavel.celular.numeric == false">Campo deve ser numérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Data de Nascimento"
                label-for="responsavel_nascimento"
                label-class="font-weight-bold required-field"
              >
                <date-picker
                  id="responsavel_nascimento"
                  name="responsavel_nascimento"
                  v-model.trim="$v.matricula.responsavel.nascimento.$model"
                  lang="pt-br"
                  type="date"
                  value-type="DD-MM-YYYY"
                  format="DD-MM-YYYY"
                  input-class="cls-datepicker"
                  :editable="true"
                  :open.sync="openDatePickerResponsavel"
                >
                  <template v-slot:input>
                    <masked-input
                      type="text"
                      name="responsavel_nascimento_input"
                      class="mx-input"
                      :value="$v.matricula.responsavel.nascimento.$model"
                      :mask="dateMask"
                      :guide="false"
                      @change="handleChangeResponsavel"
                    >
                    </masked-input>
                  </template>
                  <template #footer="{ emit }">
                    <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                  </template>
                </date-picker>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.nascimento.$invalid"
                >
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Gênero" label-for="responsavel_genero" label-class="font-weight-bold">
                <b-form-select
                  id="responsavel_genero"
                  name="responsavel_genero"
                  v-model.trim="$v.matricula.responsavel.genero.$model"
                >
                  <option value="" disabled>Selecione o gênero</option>
                  <option v-for="gen in opt_genero" v-bind:key="gen.id" :value="gen.id">{{ gen.name }}</option>
                </b-form-select>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.genero.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.genero.required == false">Campo obrigatório</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group
                label="Outro Gênero"
                label-for="responsavel_outro_genero"
                label-class="font-weight-bold required-field"
                v-show="$v.matricula.responsavel.genero.$model === otherGenreId"
              >
                <b-form-input
                  type="text"
                  v-model.trim="$v.matricula.responsavel.outro_genero.$model"
                  id="responsavel_outro_genero"
                  name="responsavel_outro_genero"
                  debounce="500"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.outro_genero.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.outro_genero.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.outro_genero.alpha == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group label="Profissão" label-for="responsavel_profissao" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.responsavel.profissao.$model"
                  type="text"
                  id="responsavel_profissao"
                  name="responsavel_profissao"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.profissao.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.profissao.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6">
              <b-form-group
                label="Empresa em que trabalha"
                label-for="responsavel_empresa"
                label-class="font-weight-bold"
              >
                <b-form-input
                  v-model.trim="$v.matricula.responsavel.empresa.$model"
                  type="text"
                  id="responsavel_empresa"
                  name="responsavel_empresa"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.empresa.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.empresa.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider">Endereço</div>
          <b-row>
            <b-col cols="12" sm="2">
              <b-form-group label="CEP" label-for="responsavel_cep" label-class="font-weight-bold required-field">
                <b-input-group>
                  <the-mask
                    id="responsavel_cep"
                    name="responsavel_cep"
                    v-model.trim="$v.matricula.responsavel.cep.$model"
                    :mask="'#####-###'"
                    type="text"
                    debounce="1000"
                    placeholder="#####-##"
                    class="form-control"
                    @blur.native="buscaCepResponsavel()"
                  />
                  <template #append>
                    <b-button variant="outline-secondary" @click="buscaCepResponsavel()" :disabled="isLoadingCepResp">
                      <i class="fa-solid fa-magnifying-glass"></i
                    ></b-button>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cep.$invalid">
                  <p v-if="$v.matricula.responsavel.cep.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.cep.numeric == false">Campo deve ser numérico</p>
                  <p v-if="$v.matricula.responsavel.cep.cepSize == false">Cep inválido</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Bairro" label-for="responsavel_bairro" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.responsavel.bairro.$model"
                    type="text"
                    id="responsavel_bairro"
                    name="responsavel_bairro"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepResp">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.bairro.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.bairro.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.bairro.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4">
              <b-form-group label="Cidade" label-for="responsavel_cidade" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.responsavel.cidade.$model"
                    type="text"
                    id="responsavel_cidade"
                    name="responsavel_cidade"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepResp">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cidade.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.cidade.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.cidade.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-form-group label="UF" label-for="responsavel_estado" label-class="font-weight-bold required-field">
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.responsavel.estado.$model"
                    type="text"
                    id="responsavel_estado"
                    name="responsavel_estado"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepResp">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.estado.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.estado.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.estado.alpha == false">Campo deve ser alfabético</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group
                label="Endereço"
                label-for="responsavel_endereco"
                label-class="font-weight-bold required-field"
              >
                <b-input-group>
                  <b-form-input
                    v-model.trim="$v.matricula.responsavel.endereco.$model"
                    type="text"
                    id="responsavel_endereco"
                    name="responsavel_endereco"
                  />
                  <template #append>
                    <b-input-group-text v-if="isLoadingCepResp">
                      <i class="fa-solid fa-spinner fa-spin"></i>
                    </b-input-group-text>
                  </template>
                </b-input-group>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.endereco.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.endereco.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.endereco.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="2">
              <b-form-group label="Número" label-for="responsavel_numero" label-class="font-weight-bold required-field">
                <b-form-input
                  v-model.trim="$v.matricula.responsavel.numero.$model"
                  type="text"
                  id="responsavel_numero"
                  name="responsavel_numero"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.numero.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.numero.required == false">Campo obrigatório</p>
                  <p v-if="$v.matricula.responsavel.numero.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4">
              <b-form-group label="Complemento" label-for="responsavel_complemento" label-class="font-weight-bold">
                <b-form-input
                  v-model.trim="$v.matricula.responsavel.complemento.$model"
                  type="text"
                  id="responsavel_complemento"
                  name="responsavel_complemento"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.complemento.$invalid"
                >
                  <p v-if="$v.matricula.responsavel.responsavel.alphaNum == false">Campo deve ser alfanumérico</p>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-row>
          <b-col>
            <b-form-group>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.termos_aceitos.$invalid"
              >
                <p v-if="$v.matricula.gerais.termos_aceitos.termsAccepted == false">
                  É necessário aceitar os termos para continuar
                </p>
              </b-form-invalid-feedback>
              <b-form-checkbox
                v-model="$v.matricula.gerais.termos_aceitos.$model"
                name="termos_aceitos"
                id="termos_aceitos"
                switch
              >
                Li e concordo com o
                <a
                  href="https://media.cpv.com.br/cpvColegio/contrato-matricula/contrato_cpv_colegio.pdf"
                  style="text-decoration-line: underline"
                  target="_blank"
                  >Contrato de Prestação
                </a>
                de Serviços Educacionais disponibilizados no item 6 e estou ciente de que o preenchimento e envio da
                Ficha de Matrícula não a efetiva nem representa Reserva de Vaga. Também estou ciente de que minha
                matrícula somente estará efetivada após o pagamento da primeira parcela e entrega de toda a documentação
                exigida.
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-button variant="primary" class="float-btn" type="submit"> Enviar </b-button> -->

        <b-row class="mt-3">
          <b-col>
            <b-button v-if="!submiting" block variant="primary" type="submit">Enviar</b-button>
            <b-button v-else block variant="primary">
              <i class="fa-solid fa-spinner fa-spin"></i>
              Enviando
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="3">
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.unidade_id.$invalid">
              <p>A UNIDADE é obrigatória</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.grade_id.$invalid">
              <p>A SÉRIE é obrigatória</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.gerais.ano_letivo.$invalid">
              <p>O ANO LETIVO é obrigatório</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.nome.$invalid">
              <p v-if="$v.matricula.aluno.nome.required == false">NOME DO Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.nome.alphaNum == false">
                NOME DO Aluno deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.aluno.nome.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.email.$invalid">
              <p v-if="$v.matricula.aluno.email.required == false">O EMAIL do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.email.email == false">O EMAIL do Aluno está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cpf.$invalid">
              <p v-if="$v.matricula.aluno.cpf.required == false">O CPF do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.cpf.cpfSize == false">O CPF do Aluno deve conter 11 números</p>
              <p v-if="$v.matricula.aluno.cpf.cpfValid == false">CPF do Aluno está Inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.rg.$invalid">
              <p v-if="$v.matricula.aluno.rg.required == false">O RG do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.rg.rgValid == false">O RG do Aluno está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.celular.$invalid">
              <p v-if="$v.matricula.aluno.celular.required == false">O CELULAR do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.celular.celularSize == false">O CELULAR do Aluno está inválido</p>
              <p v-if="$v.matricula.aluno.celular.numeric == false">O CELULAR do Aluno deve ser numérico</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.nascimento.$invalid">
              <p>A DATA DE NASCIMENTO do Aluno é obrigatória</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.genero.$invalid">
              <p v-if="$v.matricula.aluno.genero.required == false">O GÊNERO do Aluno é obrigatório</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.outro_genero.$invalid">
              <p v-if="$v.matricula.aluno.outro_genero.required == false">Especifique o GÊNERO DO Aluno</p>
              <p v-if="$v.matricula.aluno.outro_genero.alpha == false">
                O GÊNERO DO Aluno deve ser alfabético<br />
                <span v-html="validateTextAlpha($v.matricula.aluno.outro_genero.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.escola.$invalid">
              <p v-if="$v.matricula.aluno.escola.required == false">A ESCOLA do Aluno é obrigatória</p>
              <p v-if="$v.matricula.aluno.escola.alphaNum == false">
                A ESCOLA do Aluno deve ser alfanumérica<br />
                <span v-html="validateTextAlphaNum($v.matricula.aluno.escola.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.comoconheceu.$invalid">
              <p v-if="$v.matricula.aluno.comoconheceu.required == false">COMO CONHECEU é obrigatório</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cep.$invalid">
              <p v-if="$v.matricula.aluno.cep.required == false">O CEP do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.cep.numeric == false">O CEP do Aluno deve ser numérico</p>
              <p v-if="$v.matricula.aluno.cep.cepSize == false">O CEP do Aluno está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.bairro.$invalid">
              <p v-if="$v.matricula.aluno.bairro.required == false">O BAIRRO do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.bairro.alphaNum == false">
                O BAIRRO do Aluno deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.aluno.bairro.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.cidade.$invalid">
              <p v-if="$v.matricula.aluno.cidade.required == false">A CIDADE do Aluno é obrigatória</p>
              <p v-if="$v.matricula.aluno.cidade.alphaNum == false">
                A CIDADE do Aluno deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.aluno.cidade.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.estado.$invalid">
              <p v-if="$v.matricula.aluno.estado.required == false">O ESTADO do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.estado.alpha == false">
                O ESTADO do Aluno deve ser alfabético<br />
                <span v-html="validateTextAlpha($v.matricula.aluno.estado.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.endereco.$invalid">
              <p v-if="$v.matricula.aluno.endereco.required == false">O ENDEREÇO do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.endereco.alphaNum == false">
                O ENDEREÇO do Aluno deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.aluno.endereco.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.numero.$invalid">
              <p v-if="$v.matricula.aluno.numero.required == false">O NÚMERO do ENDEREÇO do Aluno é obrigatório</p>
              <p v-if="$v.matricula.aluno.numero.alphaNum == false">
                O NÚMERO do ENDEREÇO do Aluno deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.aluno.numero.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.aluno.complemento.$invalid">
              <p v-if="$v.matricula.aluno.complemento.alphaNum == false">
                O COMPLEMENTO do ENDEREÇO do Aluno deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.aluno.complemento.$model)"></span>
              </p>
            </b-form-invalid-feedback>
          </b-col>
          <b-col cols="12" :sm="$v.matricula.responsavel.responsavel.$model === 'outro' ? 3 : 4">
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.nome.$invalid">
              <p v-if="$v.matricula.pai.nome.required == false">NOME DO Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.nome.alphaNum == false">
                NOME DO Pai deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.pai.nome.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.email.$invalid">
              <p v-if="$v.matricula.pai.email.required == false">O EMAIL do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.email.email == false">O EMAIL do Pai está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cpf.$invalid">
              <p v-if="$v.matricula.pai.cpf.required == false">O CPF do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.cpf.cpfSize == false">O CPF do Pai deve conter 11 números</p>
              <p v-if="$v.matricula.pai.cpf.cpfValid == false">CPF do Pai está Inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.rg.$invalid">
              <p v-if="$v.matricula.pai.rg.required == false">O RG do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.rg.rgValid == false">O RG do Pai está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.celular.$invalid">
              <p v-if="$v.matricula.pai.celular.required == false">O CELULAR do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.celular.celularSize == false">O CELULAR do Pai está inválido</p>
              <p v-if="$v.matricula.pai.celular.numeric == false">O CELULAR do Pai deve ser numérico</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.nascimento.$invalid">
              <p>A DATA DE NASCIMENTO do Pai é obrigatória</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.genero.$invalid">
              <p v-if="$v.matricula.pai.genero.required == false">O GÊNERO do Pai é obrigatório</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.outro_genero.$invalid">
              <p v-if="$v.matricula.pai.outro_genero.required == false">Especifique o GÊNERO DO Pai</p>
              <p v-if="$v.matricula.pai.outro_genero.alpha == false">
                O GÊNERO DO Pai deve ser alfabético<br />
                <span v-html="validateTextAlpha($v.matricula.pai.outro_genero.$model)"></span>
              </p>
            </b-form-invalid-feedback>

            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.profissao.$invalid">
              <p v-if="$v.matricula.pai.profissao.alpha == false">
                A PROFISSÃO do Pai é inválida<br />
                <span v-html="validateTextAlpha($v.matricula.pai.profissao.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.empresa.$invalid">
              <p v-if="$v.matricula.pai.empresa.alpha == false">
                A EMPRESA do Pai é inválida<br />
                <span v-html="validateTextAlpha($v.matricula.pai.empresa.$model)"></span>
              </p>
            </b-form-invalid-feedback>

            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cep.$invalid">
              <p v-if="$v.matricula.pai.cep.required == false">O CEP do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.cep.numeric == false">O CEP do Pai deve ser numérico</p>
              <p v-if="$v.matricula.pai.cep.cepSize == false">O CEP do Pai está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.bairro.$invalid">
              <p v-if="$v.matricula.pai.bairro.required == false">O BAIRRO do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.bairro.alphaNum == false">
                O BAIRRO do Pai deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.pai.bairro.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.cidade.$invalid">
              <p v-if="$v.matricula.pai.cidade.required == false">A CIDADE do Pai é obrigatória</p>
              <p v-if="$v.matricula.pai.cidade.alphaNum == false">
                A CIDADE do Pai deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.pai.cidade.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.estado.$invalid">
              <p v-if="$v.matricula.pai.estado.required == false">O ESTADO do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.estado.alpha == false">
                O ESTADO do Pai deve ser alfabético<br />
                <span v-html="validateTextAlpha($v.matricula.pai.estado.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.endereco.$invalid">
              <p v-if="$v.matricula.pai.endereco.required == false">O ENDEREÇO do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.endereco.alphaNum == false">
                O ENDEREÇO do Pai deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.pai.endereco.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.numero.$invalid">
              <p v-if="$v.matricula.pai.numero.required == false">O NÚMERO do ENDEREÇO do Pai é obrigatório</p>
              <p v-if="$v.matricula.pai.numero.alphaNum == false">
                O NÚMERO do ENDEREÇO do Pai deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.pai.numero.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.pai.complemento.$invalid">
              <p v-if="$v.matricula.pai.complemento.alphaNum == false">
                O COMPLEMENTO do ENDEREÇO da Pai deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.pai.complemento.$model)"></span>
              </p>
            </b-form-invalid-feedback>
          </b-col>
          <b-col cols="12" :sm="$v.matricula.responsavel.responsavel.$model === 'outro' ? 3 : 4">
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.nome.$invalid">
              <p v-if="$v.matricula.mae.nome.required == false">NOME da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.nome.alphaNum == false">
                NOME da Mãe deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.mae.nome.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.email.$invalid">
              <p v-if="$v.matricula.mae.email.required == false">O EMAIL da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.email.email == false">O EMAIL da Mãe está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cpf.$invalid">
              <p v-if="$v.matricula.mae.cpf.required == false">O CPF da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.cpf.cpfSize == false">O CPF da Mãe deve conter 11 números</p>
              <p v-if="$v.matricula.mae.cpf.cpfValid == false">CPF da Mãe está Inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.rg.$invalid">
              <p v-if="$v.matricula.mae.rg.required == false">O RG da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.rg.rgValid == false">O RG da Mãe está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.celular.$invalid">
              <p v-if="$v.matricula.mae.celular.required == false">O CELULAR da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.celular.celularSize == false">O CELULAR da Mãe está inválido</p>
              <p v-if="$v.matricula.mae.celular.numeric == false">O CELULAR da Mãe deve ser numérico</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.nascimento.$invalid">
              <p>A DATA DE NASCIMENTO da Mãe é obrigatória</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.genero.$invalid">
              <p v-if="$v.matricula.mae.genero.required == false">O GÊNERO da Mãe é obrigatório</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.outro_genero.$invalid">
              <p v-if="$v.matricula.mae.outro_genero.required == false">Especifique o GÊNERO da Mãe</p>
              <p v-if="$v.matricula.mae.outro_genero.alpha == false">
                O GÊNERO da Mãe deve ser alfabético<br />
                <span v-html="validateTextAlpha($v.matricula.mae.outro_genero.$model)"></span>
              </p>
            </b-form-invalid-feedback>

            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.profissao.$invalid">
              <p v-if="$v.matricula.mae.profissao.alpha == false">
                A PROFISSÃO da Mãe é inválida<br />
                <span v-html="validateTextAlpha($v.matricula.mae.profissao.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.empresa.$invalid">
              <p v-if="$v.matricula.mae.empresa.alpha == false">
                A EMPRESA da Mãe é inválida<br />
                <span v-html="validateTextAlpha($v.matricula.mae.empresa.$model)"></span>
              </p>
            </b-form-invalid-feedback>

            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cep.$invalid">
              <p v-if="$v.matricula.mae.cep.required == false">O CEP da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.cep.numeric == false">O CEP da Mãe deve ser numérico</p>
              <p v-if="$v.matricula.mae.cep.cepSize == false">O CEP da Mãe está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.bairro.$invalid">
              <p v-if="$v.matricula.mae.bairro.required == false">O BAIRRO da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.bairro.alphaNum == false">
                O BAIRRO da Mãe deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.mae.bairro.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.cidade.$invalid">
              <p v-if="$v.matricula.mae.cidade.required == false">A CIDADE da Mãe é obrigatória</p>
              <p v-if="$v.matricula.mae.cidade.alphaNum == false">
                A CIDADE da Mãe deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.mae.cidade.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.estado.$invalid">
              <p v-if="$v.matricula.mae.estado.required == false">O ESTADO da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.estado.alpha == false">
                O ESTADO da Mãe deve ser alfabético<br />
                <span v-html="validateTextAlpha($v.matricula.mae.estado.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.endereco.$invalid">
              <p v-if="$v.matricula.mae.endereco.required == false">O ENDEREÇO da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.endereco.alphaNum == false">
                O ENDEREÇO da Mãe deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.mae.endereco.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.numero.$invalid">
              <p v-if="$v.matricula.mae.numero.required == false">O NÚMERO do ENDEREÇO da Mãe é obrigatório</p>
              <p v-if="$v.matricula.mae.numero.alphaNum == false">
                O NÚMERO do ENDEREÇO da Mãe deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.mae.numero.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.mae.complemento.$invalid">
              <p v-if="$v.matricula.mae.complemento.alphaNum == false">
                O COMPLEMENTO do ENDEREÇO da Mãe deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.mae.complemento.$model)"></span>
              </p>
            </b-form-invalid-feedback>
          </b-col>
          <b-col cols="12" sm="3" v-if="$v.matricula.responsavel.responsavel.$model === 'outro'">
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.nome.$invalid">
              <p v-if="$v.matricula.responsavel.nome.required == false">NOME do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.nome.alphaNum == false">
                NOME do responsável deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.responsavel.nome.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.email.$invalid">
              <p v-if="$v.matricula.responsavel.email.required == false">O EMAIL do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.email.email == false">O EMAIL do responsável está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cpf.$invalid">
              <p v-if="$v.matricula.responsavel.cpf.required == false">O CPF do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.cpf.cpfSize == false">O CPF do responsável deve conter 11 números</p>
              <p v-if="$v.matricula.responsavel.cpf.cpfValid == false">CPF do responsável está Inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.rg.$invalid">
              <p v-if="$v.matricula.responsavel.rg.required == false">O RG do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.rg.rgValid == false">O RG do responsável está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.celular.$invalid">
              <p v-if="$v.matricula.responsavel.celular.required == false">O CELULAR do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.celular.celularSize == false">O CELULAR do responsável está inválido</p>
              <p v-if="$v.matricula.responsavel.celular.numeric == false">O CELULAR do responsável deve ser numérico</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.nascimento.$invalid"
            >
              <p>A DATA DE NASCIMENTO do responsável é obrigatória</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.genero.$invalid">
              <p v-if="$v.matricula.responsavel.genero.required == false">O GÊNERO do responsável é obrigatório</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.outro_genero.$invalid"
            >
              <p v-if="$v.matricula.responsavel.outro_genero.required == false">Especifique o GÊNERO do responsável</p>
              <p v-if="$v.matricula.responsavel.outro_genero.alpha == false">
                O GÊNERO do responsável deve ser alfabético<br />
                <span v-html="validateTextAlpha($v.matricula.responsavel.outro_genero.$model)"></span>
              </p>
            </b-form-invalid-feedback>

            <b-form-invalid-feedback
              :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.profissao.$invalid"
            >
              <p v-if="$v.matricula.responsavel.profissao.alpha == false">
                A PROFISSÃO do responsável é inválida<br />
                <span v-html="validateTextAlpha($v.matricula.responsavel.profissao.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.empresa.$invalid">
              <p v-if="$v.matricula.responsavel.empresa.alpha == false">
                A EMPRESA do responsável é inválida<br />
                <span v-html="validateTextAlpha($v.matricula.responsavel.empresa.$model)"></span>
              </p>
            </b-form-invalid-feedback>

            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cep.$invalid">
              <p v-if="$v.matricula.responsavel.cep.required == false">O CEP do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.cep.numeric == false">O CEP do responsável deve ser numérico</p>
              <p v-if="$v.matricula.responsavel.cep.cepSize == false">O CEP do responsável está inválido</p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.bairro.$invalid">
              <p v-if="$v.matricula.responsavel.bairro.required == false">O BAIRRO do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.bairro.alphaNum == false">
                O BAIRRO do responsável deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.responsavel.bairro.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.cidade.$invalid">
              <p v-if="$v.matricula.responsavel.cidade.required == false">A CIDADE do responsável é obrigatória</p>
              <p v-if="$v.matricula.responsavel.cidade.alphaNum == false">
                A CIDADE do responsável deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.responsavel.cidade.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.estado.$invalid">
              <p v-if="$v.matricula.responsavel.estado.required == false">O ESTADO do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.estado.alpha == false">
                O ESTADO do responsável deve ser alfabético<br />
                <span v-html="validateTextAlpha($v.matricula.responsavel.estado.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.endereco.$invalid"
            >
              <p v-if="$v.matricula.responsavel.endereco.required == false">O ENDEREÇO do responsável é obrigatório</p>
              <p v-if="$v.matricula.responsavel.endereco.alphaNum == false">
                O ENDEREÇO do responsável deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.responsavel.endereco.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.numero.$invalid">
              <p v-if="$v.matricula.responsavel.numero.required == false">
                O NÚMERO do ENDEREÇO do responsável é obrigatório
              </p>
              <p v-if="$v.matricula.responsavel.numero.alphaNum == false">
                O NÚMERO do ENDEREÇO do responsável deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.responsavel.numero.$model)"></span>
              </p>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              :force-show="submitStatus == 'ERROR' && $v.matricula.responsavel.complemento.$invalid"
            >
              <p v-if="$v.matricula.responsavel.complemento.alphaNum == false">
                O COMPLEMENTO do ENDEREÇO do responsável deve ser alfanumérico<br />
                <span v-html="validateTextAlphaNum($v.matricula.responsavel.complemento.$model)"></span>
              </p>
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import BreadCpv from "../components/global/bread-cpv.vue";
import {
  FormPlugin,
  FormGroupPlugin,
  FormSelectPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormCheckboxPlugin,
  FormRadioPlugin,
  InputGroupPlugin,
} from "bootstrap-vue";
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormRadioPlugin);
Vue.use(InputGroupPlugin);

import ApiService from "@/common/api.service";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
// https://mengxiong10.github.io/vue2-datepicker/index.html
// https://github.com/mengxiong10/vue2-datepicker
import { TheMask } from "vue-the-mask";
import MaskedInput from "vue-text-mask";

import Loading from "@/components/loading.vue";

// Import Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { required, requiredIf, email, minLength, maxLength, between, numeric } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ü' ]*$/);
const alphaNum = helpers.regex("alphaNum", /^[a-zA-Z0-9À-ÿ'.,\s-]*$/);
const rgValid = helpers.regex("alphaNum", /^\d{1,2}\.?\d{3}\.?\d{3}-?\d{1,2}$/);

import { cpf } from "cpf-cnpj-validator";
const cpfValid = (value) => !helpers.req(value) || (value ? cpf.isValid(value) : false);
const cpfSize = (value) => !helpers.req(value) || (value ? helpers.len(value) == 11 : false);
const cepSize = (value) => !helpers.req(value) || (value ? helpers.len(value) == 8 : false);
const celularSize = (value) => !helpers.req(value) || (value ? helpers.len(value) == 11 : false);
const termsAccepted = (value) => value == true;

export default {
  name: "AdmissaoCpv",
  metaInfo: { title: "Admissão de Alunos" },
  components: { BreadCpv, DatePicker, TheMask, MaskedInput, Loading },

  data() {
    return {
      submitStatus: null,
      submiting: false,
      resp_options: [
        { text: "Pai", value: "pai" },
        { text: "Mãe", value: "mae" },
        { text: "Outro", value: "outro" },
      ],

      // Variáveis para o date-picker
      openDatePickerAluno: false,
      openDatePickerPai: false,
      openDatePickerMae: false,
      openDatePickerResponsavel: false,
      dateMask: [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
      // Fim Variáveis para o date-picker

      matricula: {
        gerais: {
          unidade_id: 1,
          ano_letivo: null,
          curso_id: null,
          grade_id: null,
          level_id: null,
          turma_id: null,
          turma_oferta_id: null,
          promo_code_code: null,
          termos_aceitos: null,
        },
        aluno: {
          cpf: null,
          rg: null,
          celular: null,
          nome: null,
          nascimento: null,
          genero: null,
          outro_genero: null,
          email: null,
          escola: null,
          comoconheceu: null,
          cep: null,
          endereco: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
        },
        pai: {
          cpf: null,
          rg: null,
          celular: null,
          nome: null,
          nascimento: null,
          genero: null,
          outro_genero: null,
          email: null,
          profissao: null,
          empresa: null,
          cep: null,
          endereco: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
        },
        mae: {
          cpf: null,
          rg: null,
          celular: null,
          nome: null,
          nascimento: null,
          genero: null,
          outro_genero: null,
          profissao: null,
          empresa: null,
          email: null,
          cep: null,
          endereco: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
        },
        responsavel: {
          responsavel: null,
          outro_responsavel: null,
          cpf: null,
          rg: null,
          celular: null,
          nome: null,
          nascimento: null,
          genero: null,
          outro_genero: null,
          profissao: null,
          empresa: null,
          email: null,
          cep: null,
          endereco: null,
          numero: null,
          complemento: null,
          bairro: null,
          cidade: null,
          estado: null,
        },
      },

      opt_parentesco: [
        { value: "Pai", nome: "Pai" },
        { value: "Mãe", nome: "Mãe" },
        { value: "aluno", nome: "Aluno" },
      ],
      grades: [],
      unidades: [],
      genders: [],
      opt_genero: [],
      otherGenreId: null,
      isLoadingCepAluno: false,
      isLoadingCepPai: false,
      isLoadingCepMae: false,
      isLoadingCepResp: false,
    };
  },
  computed: {
    anosFuturos() {
      const anoAtual = new Date().getFullYear();
      const anosFuturos = [];

      for (let i = 0; i < 3; i++) {
        anosFuturos.push((anoAtual + i).toString());
      }
      return anosFuturos;
    },
  },
  validations: {
    matricula: {
      gerais: {
        unidade_id: { required },
        grade_id: { required },
        ano_letivo: { required },
        termos_aceitos: { termsAccepted },
      },
      aluno: {
        cpf: {
          required,
          numeric,
          cpfSize,
          cpfValid,
        },
        rg: { required, rgValid },
        celular: {
          required,
          numeric,
          celularSize,
        },
        nome: { required, alphaNum },
        nascimento: { required },
        genero: { required },
        outro_genero: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.genero == this.otherGenreId;
          }),
          alpha,
        },
        email: { required, email },
        escola: { required, alphaNum },
        comoconheceu: { required },
        cep: {
          required,
          numeric,
          cepSize,
        },
        endereco: { required, alphaNum },
        numero: { required, alphaNum },
        complemento: { alphaNum },
        bairro: { required, alphaNum },
        cidade: { required, alphaNum },
        estado: { required, alpha },
      },
      pai: {
        cpf: {
          required,
          numeric,
          cpfSize,
        },
        rg: { required, rgValid },
        celular: {
          required,
          numeric,
          celularSize,
        },
        nome: { required, alphaNum },
        nascimento: { required },
        genero: { required },
        outro_genero: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.genero == this.otherGenreId;
          }),
          alpha,
        },
        email: { required, email },
        profissao: { alpha },
        empresa: { alpha },
        cep: {
          required,
          numeric,
          cepSize,
        },
        endereco: { required, alphaNum },
        numero: { required, alphaNum },
        complemento: { alphaNum },
        bairro: { required, alphaNum },
        cidade: { required, alphaNum },
        estado: { required, alpha },
      },
      mae: {
        cpf: {
          required,
          numeric,
          cpfSize,
        },
        rg: { required, rgValid },
        celular: {
          required,
          numeric,
          celularSize,
        },
        nome: { required, alphaNum },
        nascimento: { required },
        genero: { required },
        outro_genero: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.genero == this.otherGenreId;
          }),
          alpha,
        },
        profissao: { alpha },
        empresa: { alpha },
        email: { required, email },
        cep: {
          required,
          numeric,
          cepSize,
        },
        endereco: { required, alphaNum },
        numero: { required, alphaNum },
        complemento: { alphaNum },
        bairro: { required, alphaNum },
        cidade: { required, alphaNum },
        estado: { required, alpha },
      },
      responsavel: {
        responsavel: { required },
        outro_responsavel: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alpha,
        },
        cpf: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          numeric,
          cpfSize,
        },
        rg: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          rgValid,
        },
        celular: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          numeric,
          celularSize,
        },
        nome: {
          required: requiredIf((nestedModel, vm) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        nascimento: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
        },
        genero: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
        },
        outro_genero: {
          required: requiredIf(function (nestedModel) {
            return nestedModel.responsavel === "outro" && nestedModel.genero == this.otherGenreId;
          }),
          alpha,
        },
        profissao: { alpha },
        empresa: { alpha },
        email: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          email,
        },
        cep: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          numeric,
          cepSize,
        },
        endereco: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        numero: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        complemento: { alphaNum },
        bairro: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        cidade: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alphaNum,
        },
        estado: {
          required: requiredIf((nestedModel) => {
            return nestedModel.responsavel === "outro";
          }),
          alpha,
        },
      },
    },
  },
  mounted: function () {
    this.loadRecaptchaScript();
    this.getStudentAdmissionInitialData();
  },
  watch: {
    "matricula.gerais.grade_id": function (value) {
      this.matricula.gerais.level_id = this.grades.find((grd) => {
        return grd.id == value;
      }).level_id;
    },
  },
  methods: {
    getStudentAdmissionInitialData: function () {
      ApiService.get("sitecpv/getStudentEnrollmentInitialData")
        .then((response) => {
          if (response.data.status == "success") {
            this.grades = response.data.grades;
            this.unidades = response.data.unidades;
            this.opt_genero = response.data.genders;
            const otherGenre = this.opt_genero.find((gen) => {
              return Number(gen.other_gender) === 1;
            });
            if (otherGenre) {
              this.otherGenreId = otherGenre.id;
            }
          } else {
            this.$snotify.error("Ocorreu um erro");
          }
        })
        .catch((errors) => {
          console.log(errors);
          this.$snotify.error("Ocorreu um erro");
        });
    },
    loadRecaptchaScript() {
      if (!document.getElementById("recaptcha-script")) {
        const script = document.createElement("script");
        script.id = "recaptcha-script";
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lc7qt4UAAAAAAi9vH50o9GGdfVInkBtd5jU-u9B";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }
    },
    handleSubmit(e) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submit();
          this.submitStatus = "OK";
        }, 500);
      }
    },

    submit: async function () {
      this.submiting = true;

      // Obtém o token do reCAPTCHA
      const token = await grecaptcha.execute("6Lc7qt4UAAAAAAi9vH50o9GGdfVInkBtd5jU-u9B", { action: "submit" });

      // this.resetFields();
      await ApiService.post("sitecpv/postStudentEnrollment", {
        matricula: this.matricula,
        recaptchaToken: token,
      })
        .then((response) => {
          if (response.data.status == "success") {
            this.$snotify.success("Matrícula enviada com sucesso");
            (this.matricula = {
              gerais: {
                unidade_id: 1,
                ano_letivo: null,
                curso_id: null,
                grade_id: null,
                level_id: null,
                turma_id: null,
                turma_oferta_id: null,
                promo_code_id: null,
                termos_aceitos: null,
              },
              aluno: {
                cpf: null,
                rg: null,
                celular: null,
                nome: null,
                nascimento: null,
                genero: null,
                outro_genero: null,
                email: null,
                escola: null,
                comoconheceu: null,
                cep: null,
                endereco: null,
                numero: null,
                complemento: null,
                bairro: null,
                cidade: null,
                estado: null,
              },
              pai: {
                cpf: null,
                rg: null,
                celular: null,
                nome: null,
                nascimento: null,
                genero: null,
                outro_genero: null,
                email: null,
                profissao: null,
                empresa: null,
                cep: null,
                endereco: null,
                numero: null,
                complemento: null,
                bairro: null,
                cidade: null,
                estado: null,
              },
              mae: {
                cpf: null,
                rg: null,
                celular: null,
                nome: null,
                nascimento: null,
                genero: null,
                outro_genero: null,
                profissao: null,
                empresa: null,
                email: null,
                cep: null,
                endereco: null,
                numero: null,
                complemento: null,
                bairro: null,
                cidade: null,
                estado: null,
              },
              responsavel: {
                responsavel: null,
                outro_responsavel: null,
                cpf: null,
                rg: null,
                celular: null,
                nome: null,
                nascimento: null,
                genero: null,
                outro_genero: null,
                profissao: null,
                empresa: null,
                email: null,
                cep: null,
                endereco: null,
                numero: null,
                complemento: null,
                bairro: null,
                cidade: null,
                estado: null,
              },
            }),
              this.$v.$reset();
          } else {
            this.$snotify.error("Ocorreu um erro");
          }
        })
        .finally(() => {
          this.submiting = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.$snotify.error("Ocorreu um erro");
        });

      this.submiting = false;
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    validateTextAlpha(text) {
      // Percorre cada caractere da string para encontrar o primeiro que não corresponde ao regex.
      const regex = /^[a-zA-ZÀ-ü' ]*$/;
      let retText = "";
      if (text) {
        for (let i = 0; i < text.length; i++) {
          if (!regex.test(text[i])) {
            retText = retText + `Caractere inválido: '${text[i]}' na posição ${i}<br/>`;
          }
        }
      }

      return retText;
    },
    validateTextAlphaNum(text) {
      // Percorre cada caractere da string para encontrar o primeiro que não corresponde ao regex.
      const regex = /^[a-zA-Z0-9À-ü'\s\.,]*$/;
      let retText = "";
      if (text) {
        for (let i = 0; i < text.length; i++) {
          if (!regex.test(text[i])) {
            retText = retText + `Caractere inválido: '${text[i]}' na posição ${i}<br/>`;
          }
        }
      }

      return retText;
    },
    buscaCepAluno: async function () {
      if (this.matricula.aluno.cep) {
        if (this.matricula.aluno.cep.length === 8) {
          this.isLoadingCepAluno = true;
          await fetch("https://viacep.com.br/ws/" + this.matricula.aluno.cep + "/json/").then((response) => {
            response.json().then((result) => {
              if (!result.erro) {
                this.matricula.aluno.endereco = result.logradouro;
                this.matricula.aluno.bairro = result.bairro;
                this.matricula.aluno.cidade = result.localidade;
                this.matricula.aluno.estado = result.uf;
              } else {
                this.matricula.aluno.endereco = null;
                this.matricula.aluno.bairro = null;
                this.matricula.aluno.cidade = null;
                this.matricula.aluno.estado = null;
              }
              this.isLoadingCepAluno = false;
            });
          });
        }
      }
    },
    buscaCepPai: async function () {
      if (this.matricula.pai.cep) {
        if (this.matricula.pai.cep.length === 8) {
          await fetch("https://viacep.com.br/ws/" + this.matricula.pai.cep + "/json/").then((response) => {
            response.json().then((result) => {
              if (!result.erro) {
                this.matricula.pai.endereco = result.logradouro;
                this.matricula.pai.bairro = result.bairro;
                this.matricula.pai.cidade = result.localidade;
                this.matricula.pai.estado = result.uf;
              } else {
                this.matricula.pai.endereco = null;
                this.matricula.pai.bairro = null;
                this.matricula.pai.cidade = null;
                this.matricula.pai.estado = null;
              }
            });
          });
        }
      }
    },
    buscaCepMae: async function () {
      if (this.matricula.mae.cep) {
        if (this.matricula.mae.cep.length === 8) {
          await fetch("https://viacep.com.br/ws/" + this.matricula.mae.cep + "/json/").then((response) => {
            response.json().then((result) => {
              if (!result.erro) {
                this.matricula.mae.endereco = result.logradouro;
                this.matricula.mae.bairro = result.bairro;
                this.matricula.mae.cidade = result.localidade;
                this.matricula.mae.estado = result.uf;
              } else {
                this.matricula.mae.endereco = null;
                this.matricula.mae.bairro = null;
                this.matricula.mae.cidade = null;
                this.matricula.mae.estado = null;
              }
            });
          });
        }
      }
    },
    buscaCepResponsavel: async function () {
      if (this.matricula.responsavel.cep) {
        if (this.matricula.responsavel.cep.length === 8) {
          await fetch("https://viacep.com.br/ws/" + this.matricula.responsavel.cep + "/json/").then((response) => {
            response.json().then((result) => {
              if (!result.erro) {
                this.matricula.responsavel.endereco = result.logradouro;
                this.matricula.responsavel.bairro = result.bairro;
                this.matricula.responsavel.cidade = result.localidade;
                this.matricula.responsavel.estado = result.uf;
              } else {
                this.matricula.responsavel.endereco = null;
                this.matricula.responsavel.bairro = null;
                this.matricula.responsavel.cidade = null;
                this.matricula.responsavel.estado = null;
              }
            });
          });
        }
      }
    },
    copiaAlunoPai: function () {
      this.matricula.pai.cep = this.matricula.aluno.cep;
      this.matricula.pai.endereco = this.matricula.aluno.endereco;
      this.matricula.pai.numero = this.matricula.aluno.numero;
      this.matricula.pai.complemento = this.matricula.aluno.complemento;
      this.matricula.pai.bairro = this.matricula.aluno.bairro;
      this.matricula.pai.cidade = this.matricula.aluno.cidade;
      this.matricula.pai.estado = this.matricula.aluno.estado;
    },
    copiaAlunoMae: function () {
      this.matricula.mae.cep = this.matricula.aluno.cep;
      this.matricula.mae.endereco = this.matricula.aluno.endereco;
      this.matricula.mae.numero = this.matricula.aluno.numero;
      this.matricula.mae.complemento = this.matricula.aluno.complemento;
      this.matricula.mae.bairro = this.matricula.aluno.bairro;
      this.matricula.mae.cidade = this.matricula.aluno.cidade;
      this.matricula.mae.estado = this.matricula.aluno.estado;
    },
    copiaAlunoResponsavel: function () {
      this.matricula.responsavel.cep = this.matricula.aluno.cep;
      this.matricula.responsavel.endereco = this.matricula.aluno.endereco;
      this.matricula.responsavel.numero = this.matricula.aluno.numero;
      this.matricula.responsavel.complemento = this.matricula.aluno.complemento;
      this.matricula.responsavel.bairro = this.matricula.aluno.bairro;
      this.matricula.responsavel.cidade = this.matricula.aluno.cidade;
      this.matricula.responsavel.estado = this.matricula.aluno.estado;
    },
    handleChangeAluno(evt) {
      this.matricula.aluno.nascimento = evt.target.value;
      this.open = false;
    },
    handleChangePai(evt) {
      this.matricula.pai.nascimento = evt.target.value;
      this.open = false;
    },
    handleChangeMae(evt) {
      this.matricula.mae.nascimento = evt.target.value;
      this.open = false;
    },
    handleChangeResponsavel(evt) {
      this.matricula.responsavel.nascimento = evt.target.value;
      this.open = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.main-admissao {
  margin: 104.5px 0px 0px;
  font-family: "Open Sans";
  letter-spacing: -0.004em;
  .title_page {
    margin: 0px 0px -10px;
    padding: 50px 0px 45px;
    font-family: "Montserrat";
    background-repeat: repeat;
    background-position: center;
    background-color: #f16f31;
    background-image: url("../components/global/image/cpv-fundo-4.png");
    border-radius: 0px;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}
.divider {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.divider::before,
.divider::after {
  flex: 1;
  content: "";
  padding: 1px;
  background-color: rgba(0, 0, 0, 0.125);
  margin: 35px 10px;
}
.float-btn {
  position: fixed;
  right: 40px;
  bottom: 20px;
}
</style>
